import Vue from 'vue'
import { index, store, show, update, destroy } from '../../services/suppliers/suppliers'

export const suppliers = {

  namespaced: 'suppliers',

  state: {
    data: {},
    pagination: {},
    details: {},
    loading: false,
    error: null
  },

  actions: {

    index ({ state, commit }, { page, name }) {
      commit('setLoading', true)
      index(page, name)
        .then(response => {
          commit('setData', response.data.suppliers)
          commit('setPagination', response.data.suppliers)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    store ({ state, commit }, data) {
      commit('setLoading', true)
      store(data)
        .then(response => {
          commit('setDetails', response.data.supplier)
          commit('setLoading', false)
          Vue.toasted.success('Fornecedor adicionado com sucesso!')
          setTimeout(() => {
            window.location.href = '/suppliers'
          }, 1800)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar fornecedor!')
        })
    },

    show ({ state, commit }, id) {
      commit('setLoading', true)
      show(id)
        .then(response => {
          commit('setDetails', response.data.supplier)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    update ({ state, commit, dispatch }, { id, data }) {
      commit('setLoading', true)
      update(id, data)
        .then(response => {
          commit('setDetails', response.data.supplier)
          commit('setLoading', false)
          Vue.toasted.success('Fornecedor atualizado com sucesso!')
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar fornecedor!')
        })
    },

    destroy ({ state, commit }, id) {
      commit('setLoading', true)
      destroy(id)
        .then(response => {
          commit('setDetails', response.data)
          commit('setLoading', false)
          Vue.toasted.success('Fornecedor deletado com sucesso!')
          setTimeout(() => {
            window.location.href = '/suppliers'
          }, 1800)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao deletar fornecedor!')
        })
    }

  },

  mutations: {

    setData (state, suppliers) {
      state.data = suppliers
    },

    setPagination (state, paginate) {
      state.pagination = paginate
    },

    setDetails (state, supplier) {
      state.details = supplier
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
