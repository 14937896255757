import Vue from 'vue'
import { index, store, show, update, destroy } from '../../services/products/products'

export const products = {

  namespaced: 'products',

  state: {
    data: {},
    pagination: {},
    details: {},
    loading: false,
    error: null
  },

  actions: {

    index ({ state, commit }, { page, name }) {
      commit('setLoading', true)
      index(page, name)
        .then(response => {
          commit('setData', response.data.products)
          commit('setPagination', response.data.products)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    store ({ state, commit }, data) {
      commit('setLoading', true)
      store(data)
        .then(response => {
          commit('setDetails', response.data.product)
          commit('setLoading', false)
          Vue.toasted.success('Produto adicionado com sucesso!')
          setTimeout(() => {
            window.location.href = '/products'
          }, 1800)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar produto!')
        })
    },

    show ({ state, commit }, id) {
      commit('setLoading', true)
      show(id)
        .then(response => {
          commit('setDetails', response.data.product)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    update ({ state, commit, dispatch }, { id, data }) {
      commit('setLoading', true)
      update(id, data)
        .then(response => {
          commit('setDetails', response.data.product)
          commit('setLoading', false)
          Vue.toasted.success('Produto atualizado com sucesso!')
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar produto!')
        })
    },

    destroy ({ state, commit }, id) {
      commit('setLoading', true)
      destroy(id)
        .then(response => {
          commit('setDetails', response.data)
          commit('setLoading', false)
          Vue.toasted.success('Produto deletado com sucesso!')
          setTimeout(() => {
            window.location.href = '/products'
          }, 1800)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao deletar produto!')
        })
    }

  },

  mutations: {

    setData (state, products) {
      state.data = products
    },

    setPagination (state, paginate) {
      state.pagination = paginate
    },

    setDetails (state, product) {
      state.details = product
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
