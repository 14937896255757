<template>
    <div>
      <p v-if="shipping_status[0].status == 1">
        <i class="fas fa-dot-circle text-secondary mr-2"></i> Pendente
      </p>
      <p v-else-if="shipping_status[0].status == 2">
        <i class="fas fa-dot-circle text-secondary mr-2"></i> Aguardando NF
      </p>
      <p v-else-if="shipping_status[0].status == 3">
        <i class="fas fa-dot-circle text-secondary mr-2"></i> Aguardando Etiqueta
      </p>
      <p v-else-if="shipping_status[0].status == 4">
        <i class="fas fa-dot-circle text-info mr-2"></i> Aguardando Envio
      </p>
      <p v-else-if="shipping_status[0].status == 5">
        <i class="fas fa-dot-circle text-warning mr-2"></i> A caminho
      </p>
      <p v-else-if="shipping_status[0].status == 6">
        <i class="fas fa-dot-circle text-success mr-2"></i> Entregue
      </p>
      <p v-else-if="shipping_status[0].status == 7">
        <i class="fas fa-dot-circle text-info mr-2"></i> Aguardando Devolução
      </p>
      <p v-else-if="shipping_status[0].status == 8">
        <i class="fas fa-dot-circle text-warning mr-2"></i> A caminho de Devolução
      </p>
      <p v-else-if="shipping_status[0].status == 9">
        <i class="fas fa-dot-circle c-orange mr-2"></i> Em Separação
      </p>
      <p v-else>
        <i class="fas fa-bug text-danger mr-2"></i> Erro
      </p>
  </div>
</template>

<script>

export default {

  props: ['shipping_status']

}

</script>
