import { getCoupons, show, store, update, destroy, excel } from '../../services/coupons/coupons'
import Vue from 'vue'

export const coupons = {

  namespaced: 'coupons',

  state: {
    data: {},
    pagination: {},
    details: {},
    download: [],
    loading: true,
    error: null
  },

  actions: {

    getCoupons ({ state, commit }, { page, search }) {
      getCoupons(page, search)
        .then(response => {
          commit('setData', response.data.coupons)
          commit('setPagination', response.data.coupons)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    show ({ state, commit }, id) {
      show(id)
        .then(response => {
          commit('setDetails', response.data.coupon)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    store ({ state, commit, dispatch }, data) {
      store(data)
        .then(response => {
          commit('setDetails', response.data.coupon)
          commit('setLoading', false)
          Vue.toasted.success('Cupom cadastrado com sucesso!')
          dispatch('getCoupons', { page: 1, search: '' })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao cadastrar cupom!')
          dispatch('getCoupons', { page: 1, search: '' })
        })
    },

    update ({ state, commit, dispatch }, { id, data, pagination }) {
      update(id, data)
        .then(response => {
          commit('setDetails', response.data.coupon)
          commit('setLoading', false)
          Vue.toasted.success('Cupom atualizado com sucesso!')
          dispatch('getCoupons', { page: pagination, search: '' })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar cupom!')
          dispatch('getCoupons', { page: 1, search: '' })
        })
    },

    destroy ({ state, commit, dispatch }, data) {
      destroy(data)
        .then(response => {
          commit('setData', response.data.coupon)
          commit('setLoading', false)
          Vue.toasted.success('Cupom excluído com sucesso!')
          dispatch('getCoupons', { page: 1, search: '' })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.success('Erro ao excluir cupom')
          dispatch('getCoupons', { page: 1, search: '' })
        })
    },

    excel ({ state, commit }, { from, to }) {
      excel(from, to)
        .then(response => {
          commit('setDownload', response.data)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    setCouponExcelParams ({ state, commit }) {
      state.download = []
    }

  },

  mutations: {

    setData (state, coupons) {
      state.data = coupons
    },

    setDetails (state, coupon) {
      state.details = coupon
    },

    setPagination (state, paginate) {
      state.pagination = paginate
    },

    setLoading (state, status) {
      state.loading = status
    },

    setDownload (state, coupons) {
      state.download = coupons
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
