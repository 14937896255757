<template>
  <div id="content-dash" :class="[$parent.token ? 'active' : '', 'py-5']">
    <div class="container">
      <router-view/>
    </div>
  </div>
</template>

<script>

export default {

}

</script>

<style>

</style>
