<template>
  <div v-if="loading" id="loading-dash">
    <span class="text-white text-uppercase h1">
      <b>Aguarde..</b>
    </span>
  </div>
</template>

<script>

export default {

  props: ['loading']

}

</script>
